import LoginForm from 'components/LoginForm';
import { entryPoint } from 'react_rails/entryPoint';
import AuthPage from '@/components/AuthPage';
import { useApplicationContainer } from 'containers/ApplicationContainer';

export default entryPoint(function Login() {
  const [, { handleOpenLink }] = useApplicationContainer();

  return (
    <AuthPage>
      <LoginForm
        onClickForgotPassword={() => handleOpenLink('/password/reset')}
        onClickRegister={() => handleOpenLink('/register')}
        onLogin={() => handleOpenLink('/')}
      />
    </AuthPage>
  );
});
